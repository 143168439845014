<template lang="pug">
  div
    TheHeader
    .wrapper.policy
      .policy_wrap
        h2 {{ $t('privacyPolicy.title') }}
        .policy_updated {{ $t('privacyPolicy.lastUpdated') }}: {{ updatedLegal(updatedAt, this.$i18n.locale) }}

        .content
          div(
            v-for="(part, partIdx) in $t('privacyPolicy.content')"
            :key="'privacyPolicy#part#'+partIdx")
            p(v-if="part.title")
              strong(v-html="part.title")
            template(
              v-for="(text, textIdx) in part.text")
              p(
                v-if="text.trim().length"
                :key="'privacyPolicy#part#'+partIdx+'text#'+textIdx"
                v-html="text")
</template>

<script>
import TheHeader from '@/components/TheHeader';
import { updatedLegal } from '@/dates';

export default {
  components: { TheHeader },
  data() {
    return { updatedAt: '2024-07-10' };
  },
  methods: {
    updatedLegal(date, locale) {
      return updatedLegal(date, locale);
    }
  }
};
</script>

<style lang="scss" scoped>
  ul { padding-left: 40px !important; }

  .policy_wrap > div:not(.policy_updated) {
    font-weight: 700;
    font-size: 24px;
    margin-top: 40px;
  }
</style>
